import React, {useLayoutEffect, useState} from "react"
import { Parallax, ParallaxLayer } from '../components/Parallax';
import Layout from "../components/Layout";
import TitleCard from "../components/TitleCard";
import StandardScrollSection from "../components/StandardScrollSection";
import ReviewsStars from "../components/ReviewsStars";
import Ending from "../components/Ending";
import EndingMenuItem from "../components/EndingMenuItem";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { SEO } from "../components/SEO";

import {chaseGoldSlide, dropInDrop, showOutAppear, chopItUpType, victorsAppear, comeCloser} from "../helpers/animations";

const WanderersPage = () => {

  const [color, setColor] = useState("blue");
  const [itineraryLinkVisible, setitineraryLinkVisible] = useState(true);

  const sleddingLink = "https://visitsunvalley.com/winter/go-sledding-sun-valley-style/";
  const dollarLink = "https://visitsunvalley.com/winter/planning-the-winter-family-trip-to-sun-valley/";
  const iceLink = "https://visitsunvalley.com/family/ice-ice-baby-ice-skating-hockey-in-sun-valley-idaho/";
  const sunvalleyLink = "https://visitsunvalley.com/lodging/sun-valley-lodge/";
  const villageStationLink = "https://visitsunvalley.com/dining-shopping/village-station/";

  const nordicLink = "https://visitsunvalley.com/things-to-do/nordic-skiing/";
  const flyfishingLink = "https://visitsunvalley.com/things-to-do/fly-fishing/";
  const gardenLink = "https://visitsunvalley.com/summer/this-is-the-sawtooth-botanical-garden/";
  const yurtLink = "https://www.galenalodge.com/yurt-rentals.html";
  const golenaLink = "https://visitsunvalley.com/winter/experience-the-magic-of-galena-lodge/";

  const title = <>Welcome to<br/>Sun Valley, ID</>
  const formId = "5B5E7037DA78A748374AD499497E309EF2DD8A9587D3AEC7C1E6C009929E37E1AC8303DA321479E44ED5C1A687EA31584EB7613F17DAB474B39AA0A208FD1D77";
  const emailName = "cm-bdtakd-bdtakd"
  const itineraryLink = "/VSV_Winter_Itinerary_MamasPapas.pdf";
  const subTitle = "Scroll sideways to plan your family vacation today."
  const altTitle = <>Long Live<br/>Family</>

  return (
      <Layout color={color} itineraryLinkVisible={itineraryLinkVisible} itineraryLink={itineraryLink} formId={formId} emailName={emailName}>
        <Parallax pages={17.5} style={{ top: '0', left: '0' }}>
          <ParallaxLayer offset={0} speed={1}>
            <TitleCard altTitle={altTitle} title={title} instructions={subTitle} imgSrc="/VSV_LandingPage_MamasPapas_01.jpg" />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>
            <div className="ending-menu toc">
              <EndingMenuItem text="Sled" scrollTo="laugh-play" duration=".5"/>
              <EndingMenuItem text="Ski" scrollTo="ski" duration="1"/>
              <EndingMenuItem text="Skate" scrollTo="skate" duration="1.5"/>
              <EndingMenuItem text="Lodge" scrollTo="lodge" duration="2"/>
              <EndingMenuItem text="Feast" scrollTo="home-cooking" duration="2.5"/>
            </div>
          </ParallaxLayer>

          <StandardScrollSection
            id="laugh-play"
            offset={1.5}
            setNavColor={setColor}
            bgColor="red"
            titleColor="blue"
            title={<>LAUGH<br/>AND PLAY</>}
            content={<><OutboundLink href={sleddingLink} target="_blank">Sledding down snowy slopes,</OutboundLink> with family in tow, is the ultimate expression of “Winter Fun”. There are endless places to get your sled out, and no shortage of businesses that rent or sell out sleds.</>}
            intoImg='/VSV_LandingPage_MamasPapas_14.jpg'
            backgroundSvg='/mamas-papas_bg_01.svg'
            foregroundImg='/VSV_LandingPage_MamasPapas_15.png'
            trailImg='/VSV_LandingPage_MamasPapas_16.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-70%", y:"-10%", ease: "none"}, {x:"0%", y:"-20%"})
            }}
            titleAnimationFunc={dropInDrop}
            />

          <StandardScrollSection
            id="ski"
            offset={4.5}
            setNavColor={setColor}
            bgColor="brown"
            titleColor="white"
            title={<>SKI<br/>ALL DAY</>}
            content={<><OutboundLink href={dollarLink} target="_blank">Dollar Mountain</OutboundLink> is the perfect family ski destination. It offers 600 vertical feet of ideal conditions for young skiers and snowboarders. As well as three terrain parks with boxes, rails, and jumps for every ability.</>}
            intoImg='/VSV_LandingPage_MamasPapas_11.jpg'
            backgroundSvg='/mamas-papas_bg_02.svg'
            foregroundImg='/VSV_LandingPage_MamasPapas_12.png'
            trailImg='/VSV_LandingPage_MamasPapas_13.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"10%", y:"-15%", ease: "none"}, {x:"-40%", y:"0%"})
            }}
            titleAnimationFunc={chaseGoldSlide}
            />

          <StandardScrollSection
            id="skate"
            offset={7.5}
            setNavColor={setColor}
            bgColor="blue"
            titleColor="yellow"
            title={<>COME<br/>TOGETHER</>}
            content={<>Take the family skating at <OutboundLink href={iceLink} target="_blank">Christina Potter’s Ice Rink,</OutboundLink> one of the largest manmade rinks in the Valley. Skates are free and the rink is open daily. There’s even a place to step inside and warm up your kiddo's toes.</>}
            intoImg='/VSV_LandingPage_MamasPapas_02.jpg'
            backgroundSvg='/mamas-papas_bg_03.svg'
            foregroundImg='/VSV_LandingPage_MamasPapas_03.png'
            trailImg='/VSV_LandingPage_MamasPapas_04.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-50%", scale: .8, y: "0%", ease: "none"}, {x:"-20%", scale: 1, y:"0"})
            }}
            titleAnimationFunc={comeCloser}
            />

            <StandardScrollSection
              id="lodge"
              offset={10.5}
              setNavColor={setColor}
              bgColor="red"
              titleColor="brown"
              title={<span style={{
                fontSize:".73em",
                lineHeight: .8,
                display: "inline-block"
              }}>LEGENDARY<br/>LODGING</span>}
              content={<>Shack up at the <OutboundLink href={sunvalleyLink} target="_blank">Sun Valley Lodge,</OutboundLink> beloved destination of Hollywood stars and ski enthusiasts alike. If you’re really feeling lucky, try a night in the Eastwood Suite, named in honor of local legend, Clint Eastwood.</>}
              intoImg='/VSV_LandingPage_MamasPapas_05.jpg'
              backgroundSvg='/mamas-papas_bg_04.svg'
              foregroundImg='/VSV_LandingPage_MamasPapas_06.png'
              trailImg='/VSV_LandingPage_MamasPapas_07.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: 1, y: "0%", x:"-28%", ease: "none"}, { scale: 2, y:"-10px", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <StandardScrollSection
              id="home-cooking"
              offset={13.5}
              setNavColor={setColor}
              bgColor="yellow"
              titleColor="red"
              title={<span style={{
                fontSize:".95em",
                lineHeight: .8,
                display: "inline-block"
              }}>HOME<br/>COOKING</span>}
              content={<>Treat the family to some of the best burgers and wings in town at the <OutboundLink href={villageStationLink} target="_blank">Village Station.</OutboundLink> Four big screen televisions and ample seating make it the perfect place to gather after a day of frolicking in the snow.</>}
              intoImg='/VSV_Winter_MamasPapas_Homecooking_01.jpg'
              backgroundSvg='/mamas-papas_bg_05.svg'
              foregroundImg='/VSV_Winter_MamasPapas_Homecooking_Isolated.png'
              trailImg='/VSV_Winter_MamasPapas_Homecooking_02.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .2, y: "-30%", x:"-25%", ease: "none"}, { scale: 1.7, y: "30%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <ReviewsStars offset={16.5} rating={4.6}/>

        </Parallax>
      </Layout>
    );
}

export default WanderersPage


export const Head = () => (
  <SEO title="Mamas and Papas, Sun Valley Wants You" image="/SEO_VSV_LandingPage_MamasPapas_01.jpg" description="Are you a parent in search of a unique family vacation? Sun Valley, ID wants you." />
)
